<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="4">
        <v-card elevation="1" class="mx-auto" color="#3c9bf9">
          <v-card-title>Signing Out</v-card-title>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  created() {
    this.signOut();
  },
  data() {
    return {
      valid: true,
      loading: true,
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    signOut() {
      this.api("auth/logout", null)
        .then(() => {
          this.loading = false;
          localStorage.setItem("er_client_user", "");
          localStorage.setItem("er_client_token", "");
          document.location.href = "/sign-in";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "SignIn",
};
</script>
